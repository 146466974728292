import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Settings from "@material-ui/icons/Settings";
import classnames from "classnames";
import React, { useContext } from "react";
import { Loading } from "../components/Loading";
import { Metric, Query } from "../lib/api/charts";
import { MetricContext } from "../contexts/MetricContext";
import { OemContext } from "../contexts/OemContext";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import { history } from "../lib/history";
import { Route, Switch } from "react-router-dom";
import { BenchmarkContext } from "../contexts/BenchmarkContext";
import { MetricInsightContext } from "../contexts/MetricInsightContext";
import { DealerContext } from "../contexts/DealerContext";
import { FormattedMessage, useIntl } from "react-intl";
import { translation } from "../translations/Translations";
import { DashboardConfigContext } from "../contexts/DashboardConfigContext";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export const styles = (theme: Theme) =>
  createStyles({
    cardRoot: {
      border: "1px #DADADA solid",
      height: "270px",
      borderRadius: "5px",
      // boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06) !important",
      padding: `${theme.spacing.unit * 2}px`,
    },
    downArrow: {
      fill: "#636463",
      marginTop: "2px",
      marginRight: "10px",
      marginLeft: "5px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    cardContent: {
      height: "160px",
      display: "grid",
      gridTemplateAreas: `
      "value value value"
      "median median median"
      "median median median"
      "count count count"
      "target target target"
      "target target target"
    `,
      gridGap: `${theme.spacing.unit}px`,
      gridTemplateColumns: "1fr 1fr",
      backgroundColor: "#FFF",
      borderBottom: 0,
      padding: theme.spacing.unit,
      color: "#191919",
      gridTemplateRows: "auto",
    },
    cardDriverContent: {
      display: "grid",
      gridTemplateAreas: `
      "content"
    `,
      gridGap: `${theme.spacing.unit}px`,
      gridTemplateColumns: "auto",
      backgroundColor: "#f8f8f8",
      borderRight: "solid 3px #f3f3f3",
      borderLeft: "solid 3px #f3f3f3",
      padding: theme.spacing.unit,
      color: "#555",
    },
    header: {
      display: "grid",
      backgroundColor: "#FFF",
      gridGap: `${theme.spacing.unit}px`,
      padding: theme.spacing.unit,
      paddingBottom: 0,
      color: "#191919",
    },
    bold: {
      fontWeight: "bold",
    },
    boldRight: {
      display: "flex",
      fill: "#636463",
      justifyContent: "flex-end",
      // fontWeight: 400,
    },
    heading: {
      height: "55px",
    },
    headingChildren: {
      fontWeight: "bold",
      fontSize: `${theme.typography.fontSize + 2}px`,
    },
    mainTitleText: {
      fontSize: `${theme.typography.fontSize * 2 + 2}px`,
      whiteSpace: "nowrap",
    },
    mainTitle: {
      textDecoration: "underline",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    median: {
      gridArea: "median",
      display: "grid",
      gridTemplateColumns: "auto auto",
      gridTemplateRows: "1fr 1fr",
    },
    cValue: {
      gridArea: "count",
      display: "grid",
      gridTemplateColumns: "auto auto",
      gridTemplateRows: "1fr",
    },
    title: {
      color: "#636463",
      fontWeight: 600,
      fontSize: `${theme.typography.fontSize + 3}px`,
      textDecoration: "none",
    },
    nowrap: {
      height: `${theme.typography.fontSize + 6}px`,
      whiteSpace: "nowrap",
    },
    bmValue: {
      color: "#636463",
      fontSize: `${theme.typography.fontSize + 3}px`,
      paddingLeft: "4px",
    },
    measurement: {
      gridArea: "measurement",
    },
    mainValue: {
      textAlign: "right",
      border: "solid 3px transparent",
      borderRight: "none",
      cursor: "pointer",
      alignItems: "center",
      marginBottom: 4,
      lineHeight: 0,
      fontSize: `${theme.typography.fontSize * 2.5 + 4}px`,
    },
    value: {
      gridArea: "value",
    },
    target: {
      gridArea: "target",
      gridTemplateRows: "1fr 1fr",
    },
    count: {
      gridArea: "count",
    },
    countValue: {
      gridArea: "countValue",
    },
    targetHeader: {
      fontSize: `${theme.typography.fontSize + 3}px`,
      color: "#636463",
    },
    targetValue: {
      fill: "#636463",
      fontSize: `${theme.typography.fontSize + 3}px`,
      fontWeight: "bold",
    },
    settings: {
      fill: "#636463",
      "&:hover": {
        cursor: "pointer",
      },
    },
    green: {
      color: "#4fdf4d",
    },
    yellow: {
      color: orange[500],
    },
    red: {
      color: red[500],
    },
    valueTargetIcon: {
      marginRight: "2px",
    },
    actions: {
      marginTop: "-22px",
      flexDirection: "row",
      alignItems: "center",
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
      [theme.breakpoints.up("xl")]: {
        display: "flex",
      },
    },
    valueSpacing: {
      paddingLeft: theme.spacing.unit * 1,
    },
    lineSpacing: {
      paddingTop: theme.spacing.unit * 0.5,
    },
    hideCard: {
      display: "none",
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
      [theme.breakpoints.up("xl")]: {
        display: "block",
      },
    },
    subheading: {
      fontSize: `${theme.typography.fontSize + 3}px`,
    },
  });

export type MetricProps = {
  query: Query;
  title?: string;
  metric: any;
  allowFlip?: boolean;
  renderIcon?(): React.ReactNode;
  driverMetric?: string;
  oem?: boolean;
} & React.HtmlHTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

export type MetricHeaderProps = {
  title: string;
  driverMetric?: string;
  override?: boolean;
} & React.HtmlHTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const MetricHeader: React.FunctionComponent<MetricHeaderProps> = ({ classes, title, driverMetric, children, override = true }) => {
  const { dashboardTypeConfig } = useContext(DashboardConfigContext);
  const intl = useIntl();
  const translatedTitle = override
    ? translation[title]
      ? intl.formatMessage({ id: translation[title] })
      : title
    : translation[title]
    ? intl.formatMessage({ id: translation[title] })
    : title;

  return (
    <div className={classnames(classes.title, classes.mainTitle, classes.bold, classes.header)}>
      {translatedTitle.includes("-") ? (
        <span className={classes.heading}>
          <Typography className={classnames(classes.bold, classes.headingChildren)} variant="h6" color="inherit">
            {translatedTitle.split(" - ")[0]}
          </Typography>
          <TruncatedMetricText
            className={classnames(classes.bold, classes.headingChildren, classes.subheading)}
            value={translatedTitle.split(" - ")[1]}
            greaterThan={33}
            truncateAter={30}
            metricTitle={true}
          />
        </span>
      ) : (
        <span className={classes.heading}>
          <Typography className={classnames(classes.bold, classes.headingChildren)} variant="h6" color="inherit">
            {translatedTitle}
          </Typography>
          {dashboardTypeConfig.value == "mra_drivers" && driverMetric && (
            <Typography className={classnames(classes.bold, classes.headingChildren, classes.subheading)} variant="h6" color="inherit">
              {driverMetric}
            </Typography>
          )}
        </span>
      )}
      {children}
    </div>
  );
};

const criteriaMet = (metric: Metric, key: "previous" | "target") => {
  if (metric.classification == "income") {
    return metric.actual.value >= metric[key].value;
  }

  return metric.actual.value <= metric[key].value;
};

export type ValueTargetIconProps = {
  metric: Metric;
  criteriaMet: boolean;
} & React.HtmlHTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const ValueTargetIcon: React.FunctionComponent<ValueTargetIconProps> = ({ classes, criteriaMet }) => {
  return (
    <FontAwesomeIcon
      className={classnames(classes.valueTargetIcon, criteriaMet ? classes.green : classes.red)}
      icon={criteriaMet ? faCheckCircle : faTimesCircle}
    />
  );
};

export type MainValueProps = {
  metric: Metric;
} & React.HtmlHTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const metricColour = (metric: Metric, classes: any) => {
  if (metric.classification == "income") {
    return {
      [classes.yellow]: metric.actual.value >= metric.median.value && metric.actual.value < metric.benchmark.value,
      [classes.red]: metric.actual.value < metric.median.value,
    };
  }
  return {
    [classes.yellow]: metric.actual.value < metric.median.value && metric.actual.value > metric.benchmark.value,
    [classes.red]: metric.actual.value > metric.median.value,
  };
};

const MainValue: React.FunctionComponent<MainValueProps> = ({ classes, metric }) => {
  return (
    <div className={classnames(classes.value, classes.mainValue, classes.bold)}>
      <Typography
        className={classnames(classes.bold, classes.mainTitleText, classes.mainValue, metricColour(metric, classes))}
        variant="subheading"
        color="inherit"
      >
        {replaceNonNumbers(metric.actual.formatted)}
      </Typography>
    </div>
  );
};

const replaceNonNumbers = (value: any) => {
  if ((value || "").includes("NaN") || (value || "").includes("Infinity")) {
    return "";
  }
  return value;
};

export const TruncatedMetricText: React.SFC<{ className?: string; value: string; greaterThan?: number; truncateAter?: number; metricTitle?: boolean }> = ({
  className,
  value,
  greaterThan = 7,
  truncateAter = 4,
  metricTitle = false,
}) => {
  if (value && value.length > greaterThan) {
    return (
      <Tooltip title={value}>
        <span className={className}>{`${value.substr(0, truncateAter)}...${metricTitle ? "" : ")"}`}</span>
      </Tooltip>
    );
  }
  return <span className={className}>{value}</span>;
};

export const prevVal = (period: string) => {
  switch (period) {
    case "Monthly":
      return "MoM";
    case "TMRA":
      return "TMRA YoY";
    case "Financial Year":
      return "FYTD YoY";
    case "Calendar Year":
      return "CYTD YoY";
    default:
      return "Y-1";
  }
};

const MetricUnstlyed: React.FunctionComponent<MetricProps> = ({
  classes,
  title,
  metric: report,
  query,
  allowFlip = true,
  renderIcon,
  oem = false,
  driverMetric = undefined,
}) => {
  const { getMetric } = useContext(MetricContext);
  const { oems } = useContext(OemContext);
  const { metric, loading } = getMetric(report, query);
  const { selected: benchmarks, oemSelected: oemBenchmarks } = useContext(BenchmarkContext);
  const intl = useIntl();

  const [anchorEl, setAnchorEl] = React.useState(null);

  if (!loading.loaded || loading.loading) {
    return <Loading />;
  }

  // const override = translation[metric.titleOverride] ? translation[metric.titleOverride] : "";
  // const overrideExist = override.includes("metric.card.override.");
  // const metricTitle = overrideExist ? intl.formatMessage({ id: override }) : title || metric.title;

  const override = !!metric.titleOverride ? intl.formatMessage({ id: metric.titleOverride }) : "";
  const overrideExist = override.includes("metric.card.override.");
  const metricTitle = oem ? title || metric.title : overrideExist ? title || metric.title : override;

  const benchmarksType = (oem ? oemBenchmarks : benchmarks).map(x => {
    const [bench, part] = x.split(/-(?=[^-]+$)/);
    switch ((part || "").trim()) {
      case "MD":
        return "metric.median.label";
      case "BM":
        return "metric.benchmark.label";
      case "AV":
        return "metric.average.label";
      case "UL":
        return "metric.upper.limit.label";
      case "LL":
        return "metric.lower.limit.label";
      default:
        return "metric.benchmark.label";
    }
  });

  const bmTypeLookUp = {
    "MD:": "Median Value",
    "BM:": "Benchmark Value",
    "AVG:": "Average Value",
    "UL:": "Upper Limit Value",
    "LL:": "Lower Limit Value",
  };

  return (
    <>
      <Card className={classnames(classes.cardRoot, metricTitle == "N/A" && classes.hideCard)} component={"section"}>
        <MetricHeader classes={classes} title={metricTitle} driverMetric={driverMetric} override={overrideExist}>
          <div className={classes.actions}>
            {renderIcon && renderIcon()}

            {allowFlip && metric && metricTitle != "N/A" && (
              <Switch>
                <Route
                  path="/oem/fixed"
                  render={({ match }) => {
                    return (
                      <Settings
                        onClick={() => {
                          history.push(`/oem/fixed/home/insight/${report}/${query.department}`);
                        }}
                        className={classes.settings}
                      />
                    );
                  }}
                />
                <Route
                  path="/dashboard/:dashboard"
                  render={({ match }) => {
                    return (
                      <Settings
                        onClick={() => {
                          history.push(`/dashboard/${match.params.dashboard}/insight/${report}/${query.department}`);
                        }}
                        className={classes.settings}
                      />
                    );
                  }}
                />
              </Switch>
            )}
          </div>
        </MetricHeader>
        {/* {metric && <MainValue classes={classes} metric={metric} />} */}
        {/* {dashboardTypeConfig.value == 'mra_drivers' && <div className={classes.cardDriverContent}>
          {driverMetric && <Typography className={classnames(classes.bold, classes.headingChildren, classes.subheading)} variant="h6" color="primary">
            {driverMetric}
          </Typography>}
        </div>} */}
        <CardContent className={classes.cardContent}>
          {metric && <MainValue classes={classes} metric={metric} />}
          {benchmarks[0] && metric && metric.benchmark && (
            <div className={classnames(classes.median)}>
              <Typography
                component="div"
                color="inherit"
                className={classnames(classes.title, classes.nowrap)}
                title={`${benchmarks[0]} - ${bmTypeLookUp[benchmarksType[0]]}`}
              >
                <FormattedMessage id={benchmarksType[0]} />
              </Typography>
              <Typography
                title={`${benchmarks[0]} - ${bmTypeLookUp[benchmarksType[0]]}`}
                className={classnames(classes.bold, classes.boldRight, classes.bmValue)}
              >
                {replaceNonNumbers(metric.benchmark.formatted)}
              </Typography>

              {benchmarksType.length > 1 && (
                <>
                  <Typography
                    component="div"
                    color="inherit"
                    className={classnames(classes.title, classes.nowrap)}
                    title={`${benchmarks[1]} - ${bmTypeLookUp[benchmarksType[1]]}`}
                  >
                    <FormattedMessage id={benchmarksType[1]} />
                  </Typography>
                  <Typography
                    title={`${benchmarks[1]} - ${bmTypeLookUp[benchmarksType[1]]}`}
                    className={classnames(classes.bold, classes.boldRight, classes.bmValue)}
                  >
                    {replaceNonNumbers(metric.median.formatted)}
                  </Typography>
                </>
              )}
            </div>
          )}
          {!!metric.dealerCount.value && metric.dealerCount.value > 0 && (
            <div className={classnames(classes.cValue)}>
              <Typography
                component="div"
                color="inherit"
                className={classnames(classes.title, classes.nowrap)}
                title={`${benchmarks[0]} - ${bmTypeLookUp[benchmarksType[0]]}`}
              >
                <FormattedMessage id={"metric.card.dealer.count.label"} />
              </Typography>
              <Typography className={classnames(classes.bold, classes.boldRight, classes.bmValue)}>{metric.dealerCount.value}</Typography>
            </div>
          )}
          {metric && (
            <div className={classes.target}>
              {metric.previous.formatted === "" && metric.variance.formatted === "" ? (
                <div>
                  <span style={{ visibility: "hidden" }}>-</span>
                  {/* Placeholder to maintain element height */}
                </div>
              ) : (
                <div>
                  <span>
                    <ValueTargetIcon classes={classes} metric={metric} criteriaMet={criteriaMet(metric, "previous")} />
                    <span className={classnames(classes.targetHeader, classes.valueSpacing)}>{`${prevVal(query && query.period)}: `}</span>
                    <TruncatedMetricText
                      className={classes.targetValue}
                      truncateAter={8}
                      greaterThan={10}
                      value={metric.previous.formatted != "" ? replaceNonNumbers(metric.previous.formatted) : "N/A"}
                    />
                  </span>
                  <span className={classes.valueSpacing}>
                    <span className={classes.targetHeader}>Var </span>
                    <TruncatedMetricText
                      className={classes.targetValue}
                      truncateAter={8}
                      greaterThan={10}
                      value={metric.previous.formatted != "" && metric.variance.formatted != "" ? replaceNonNumbers(metric.variance.formatted) : "N/A"}
                    />
                  </span>
                </div>
              )}
              {query.dealer.hasTargets &&
                oems.filter(oem => query.dealer.oems.includes(oem.code)).find(oem => oem.hasTargets) &&
                (metric.target.formatted != "" || metric.targetVariance.formatted != "" ? (
                  <div className={classes.lineSpacing}>
                    <span>
                      <ValueTargetIcon classes={classes} metric={metric} criteriaMet={criteriaMet(metric, "target")} />
                      <span className={classnames(classes.targetHeader, classes.valueSpacing)}>
                        <FormattedMessage id="metric.target.label" />{" "}
                      </span>
                      <TruncatedMetricText
                        className={classes.targetValue}
                        truncateAter={8}
                        greaterThan={10}
                        value={metric.target.formatted != "" ? replaceNonNumbers(metric.target.formatted) : "N/A"}
                      />
                    </span>
                    <span className={classes.valueSpacing}>
                      <span className={classes.targetHeader}>Var </span>
                      <TruncatedMetricText
                        className={classes.targetValue}
                        truncateAter={8}
                        greaterThan={10}
                        value={
                          metric.target.formatted != "" || metric.targetVariance.formatted != "" ? replaceNonNumbers(metric.targetVariance.formatted) : "N/A"
                        }
                      />
                    </span>
                  </div>
                ) : (
                  <div className={classes.lineSpacing}>
                    <span style={{ visibility: "hidden" }}>-</span>
                    {/* Placeholder to maintain element height */}
                  </div>
                ))}
            </div>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export const MetricCard = withStyles(styles)(MetricUnstlyed);
