import { createStyles, Theme, withStyles, WithStyles, withTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classnames from "classnames";
import React, { useContext } from "react";
import { Loading } from "../components/Loading";
import { MetricReport, Query } from "../lib/api/charts";
import { CustomMetricTableContext } from "../contexts/Custom/MetricTableContext";
import { ExportButtons } from "../components/ExportButtons";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { MetricGuidance } from "../components/MetricGuidance";
import { FormattedMessage } from "react-intl";
import { translation } from "../translations/Translations";
import { MeasuresContext } from "../contexts/MeasuresContext";
import { BreakdownContext } from "../contexts/BreakdownContext";
import { CustomDealerContext } from "../contexts/CustomDealerContext";
import { OemContext } from "../contexts/OemContext";
import { LanguageContext } from "../contexts/LanguageContext";
import { add } from "date-fns";
import { red, green } from "@material-ui/core/colors";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { viewOptions } from "../pages/custom/dealer/CustomInsight";
import { SpecialCustomTable } from "./SpecialCustomTable";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      flex: 1,
      borderRadius: "3px",
    },
    heading: {
      color: "#2f2f2f",
      fontWeight: "bold",
      marginBottom: "32px",
      fontSize: "10px",
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    table: {
      border: "1px solid #e0e0e0",
      backgroundColor: "#FFF",
      "& th": {
        border: "solid #e0e0e0",
        borderWidth: "0px 0px 1px 0px",
      },
      "& td": {
        border: "solid #e0e0e0",
        borderWidth: "0px 0px 1px 0px",
      },
    },
    stickyHeader: { position: "sticky", top: "0px", zIndex: 9 },
    stickyHeader2: { position: "sticky", top: "33px", zIndex: 9 },
    tableHeaderTop: { zIndex: 99 },
    tableHeader: {
      whiteSpace: "nowrap",
      padding: `${theme.spacing.unit}px`,
      fontSize: "10px",
      textAlign: "right",
      color: "#2f2f2f",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#FFF",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    healthTableHeader: {
      padding: `${theme.spacing.unit}px`,
      fontSize: "10px",
      textAlign: "right",
      color: "#2f2f2f",
      fontWeight: "bold",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#FFF",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    tableCell: {
      padding: `${theme.spacing.unit}px`,
      fontSize: "10px",
      whiteSpace: "nowrap",
      textAlign: "right",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#FFF",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    tableCellSubHeaderFoa: {
      textAlign: "left",
      minWidth: "100px",
      fontWeight: "bold",
    },
    tableCellSubFoa: {
      textAlign: "left",
      minWidth: "100px",
      paddingLeft: `${theme.spacing.unit * 2}px`,
    },
    rule: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textAlign: "left",
    },
    ruleBold: {
      fontWeight: "bold",
    },
    good: {
      background: "#92D050 !important",
      color: "white",
      textAlign: "center",
    },
    bad: {
      background: "#FF0000 !important",
      color: "white",
      textAlign: "center",
    },
    note: {
      color: "#191919",
      marginLeft: "25px",
    },
    tableContentIndent: {
      border: "solid #e0e0e0",
      borderWidth: "0px 0px 1px 0px",
      padding: `${theme.spacing.unit}px`,
      textAlign: "right",
      fontSize: "10px",
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    orange: {
      color: "orange",
    },
    yellow: {
      color: "orange",
    },
    tableCellNull: {
      background: "#ededed",
      "&:nth-child(even)": {
        backgroundColor: "#e8e8e8",
      },
    },
    tableHeadingHeight: {
      height: "5em",
      // width: "2em",
      whiteSpace: "normal",
      fontWeight: "bold",
    },
    tableHeading: {
      padding: `${theme.spacing.unit}px`,
      textAlign: "left",
      color: "#2f2f2f",
      fontSize: "10px",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#FFF",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      fontWeight: "bold",
      width: "400px",
      minWidth: "400px",
      maxWidth: "400px",
      whiteSpace: "nowrap",
      position: "sticky",
      left: "34px",
      backgroundColor: "#f3f3f3",
    },
    green: {
      color: green[500],
    },
    red: {
      color: red[500],
    },
    pointer: {
      cursor: "pointer",
    },
    tableGuidance: {
      padding: "8px !important",
      maxWidth: "34px",
      width: "34px",
      minWidth: "34px",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#FFF",
      },
      position: "sticky",
      left: "0px",
    },
    tableCellSubHeader: {
      textAlign: "left",
      width: "400px",
      minWidth: "400px",
      maxWidth: "400px",
      whiteSpace: "nowrap",
      position: "sticky",
      left: "34px",
      backgroundColor: "#f3f3f3",
    },
    healthTableCellSubHeader: {
      textAlign: "left",
      minWidth: "100px",
      backgroundColor: "#f3f3f3",
    },
    tableCellSubHeaderBM: {
      textAlign: "left",
      width: "125px",
      minWidth: "125px",
      maxWidth: "125px",
      whiteSpace: "nowrap",
      position: "sticky",
      left: "434px",
      backgroundColor: "#FFF",
    },
    tableCellSubHeaderMD: {
      textAlign: "left",
      width: "125px",
      minWidth: "125px",
      maxWidth: "125px",
      whiteSpace: "nowrap",
      position: "sticky",
      left: "559px",
      backgroundColor: "#f3f3f3",
    },
    tableWrapper: {
      // maxWidth: margin.width,
      maxHeight: "50em",
      overflowX: "scroll",
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "white",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#cecece",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#b3b3b3",
      },
    },
    tableCellTargetA: {
      textAlign: "left",
      width: "80px",
      minWidth: "80px",
      maxWidth: "80px",
      whiteSpace: "nowrap",
      position: "sticky",
      left: "684px",
      backgroundColor: "#FFF",
    },
    healthSummaryHeader: {
      textAlign: "center",
      whiteSpace: "nowrap",
      padding: `${theme.spacing.unit}px`,
      fontSize: "12px",
      color: "#2f2f2f",
      fontWeight: "bold",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    hidden: {
      display: "none",
    },
  });

export type MetricTableProps = {
  title: string;
  report: MetricReport;
  query: Query;
  custom?: any;
  enforceYtd?: boolean;
} & WithStyles<typeof styles>;

export enum TableRows {
  Space = "Empty Space",
  Header = "Empty Measure",
}

export const ROOATables = [13, 37, 51, 64, 76, 94, 110, 126, 139, 151, 163];

const MetricTableUnstyled: React.FunctionComponent<MetricTableProps> = ({ classes, title, report, query, custom, enforceYtd = false }) => {
  const heading = null;

  const { getMeasures } = React.useContext(MeasuresContext);
  const { language } = React.useContext(LanguageContext);
  const targetCurrency = useContext(CurrencyContext);

  const getGuidanceStr = (metric: string) => {
    const currentMeasure = getMeasures().find(dbmeasure => dbmeasure.title == metric) || { description: "" };
    if (language != "en" && !!currentMeasure[`${language}_description`]) {
      return currentMeasure[`${language}_description`];
    }
    return currentMeasure.description;
  };

  const { getCustomMetricTable } = useContext(CustomMetricTableContext);
  const dealer = useContext(CustomDealerContext);

  if (ROOATables.includes(custom.tableNumber) && query.meta.variant == "default") {
    return <SpecialCustomTable classes={classes} query={query} custom={custom} heading={heading} />;
  }

  const { metric, loading } = getCustomMetricTable(report, { ...query, department: custom.department }, custom);
  const { oems } = useContext(OemContext);

  const hasTargets = dealer.customSelected.hasTargets && oems.filter(oem => query.dealer.oems.includes(oem.code)).find(oem => oem.hasTargets);

  if (loading.loading) {
    return <Loading />;
  }

  const formatData = data =>
    data == "0%" || data == "0.00%" || data == "0.0%" || data == 0 || (data || "").includes("NaN") || (data || "").includes("Infinity") ? "-" : data || "N/A";

  const benchmarks = query.benchmarks.length == 2 ? query.benchmarks : [query.benchmarks[0], query.benchmarks[0]];

  return (
    <div className={classes.root}>
      {heading}
      <ExportButtons title={title} id={"custom-table"} />
      <div className={classes.tableWrapper}>
        <Table className={classes.table} id={"custom-table"}>
          <TableHead>
            <TableRow>
              <TableCell className={classnames(classes.tableGuidance, classes.tableHeaderTop, classes.stickyHeader)} />
              <TableCell className={classnames(classes.tableHeading, classes.tableHeaderTop, classes.tableCellSubHeader, classes.stickyHeader)}>
                {translation[metric.name] ? <FormattedMessage id={translation[metric.name]} /> : metric.name} (
                {targetCurrency.targetCurrency ? targetCurrency.targetCurrency : dealer.customSelected.currency})
              </TableCell>
              {metric.columns.map((name, i) => {
                if (query.meta.variant == "default" && name.includes("Target")) {
                  if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                    return;
                  }
                  return (
                    <TableCell variant={"body"} key={name} className={classnames(classes.tableHeader, classes.stickyHeader)}>
                      {metric.columns[i]}
                    </TableCell>
                  );
                }
                if (query.meta.variant != "default" && name.includes("Target")) {
                  if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                    return;
                  }
                  return (
                    <TableCell variant={"body"} key={name} className={classnames(classes.tableHeader, classes.stickyHeader)}>
                      {metric.columns[i]} 2
                    </TableCell>
                  );
                }
                if (i < 2 && benchmarks[0] != "") {
                  return (
                    <TableCell
                      variant={"body"}
                      key={name}
                      className={classnames(
                        classes.tableHeader,
                        i == 0 && benchmarks[0] != "" && classes.tableCellSubHeaderBM,
                        i == 1 && benchmarks[0] != "" && classes.tableCellSubHeaderMD,
                        classes.tableHeaderTop,
                        classes.stickyHeader,
                      )}
                    >
                      {benchmarks[i].split(" - ")[0]}
                    </TableCell>
                  );
                }
                return (
                  <TableCell variant={"body"} key={name} className={classnames(classes.tableHeader, classes.stickyHeader)}>
                    {enforceYtd && query.meta.variant == "monthly" ? metric.columns[i].replace("MTD", "CYTD") : metric.columns[i]}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell className={classnames(classes.tableGuidance, classes.tableHeaderTop, classes.stickyHeader2)} />
              <TableCell className={classnames(classes.tableHeading, classes.tableHeaderTop, classes.tableCellSubHeader, classes.stickyHeader2)} />
              {metric.columns.map((name, i) => {
                if (query.meta.variant == "default" && name.includes("Target")) {
                  if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                    return;
                  }
                  return (
                    <TableCell
                      variant={"body"}
                      key={name}
                      className={classnames(
                        classes.tableHeader,
                        i == 0 && benchmarks[i] !== "" && classes.tableCellSubHeaderBM,
                        i == 1 && benchmarks[i] !== "" && classes.tableCellSubHeaderMD,
                      )}
                    >
                      {metric.subcolumns[i]}
                    </TableCell>
                  );
                }
                if (query.meta.variant != "default" && name.includes("Target")) {
                  if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                    return;
                  }
                  return (
                    <TableCell
                      variant={"body"}
                      key={name}
                      className={classnames(
                        classes.tableHeader,
                        i == 0 && benchmarks[i] !== "" && classes.tableCellSubHeaderBM,
                        i == 1 && benchmarks[i] !== "" && classes.tableCellSubHeaderMD,
                      )}
                    >
                      {metric.subcolumns[i]}
                    </TableCell>
                  );
                }
                if (i < 2 && benchmarks[0] != "") {
                  return (
                    <TableCell
                      variant={"body"}
                      key={name}
                      className={classnames(
                        classes.tableHeader,
                        i == 0 && benchmarks[0] != "" && classes.tableCellSubHeaderBM,
                        i == 1 && benchmarks[0] != "" && classes.tableCellSubHeaderMD,
                        classes.tableHeaderTop,
                        classes.stickyHeader2,
                      )}
                    >
                      {enforceYtd && query.meta.variant == "monthly" ? metric.subcolumns[i].replace("MTD", "CYTD") : metric.subcolumns[i]}
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    variant={"body"}
                    key={name}
                    className={classnames(
                      classes.tableHeader,
                      i == 0 && benchmarks[0] != "" && classes.tableCellSubHeaderBM,
                      i == 1 && benchmarks[0] != "" && classes.tableCellSubHeaderMD,
                      classes.stickyHeader2,
                    )}
                  >
                    {enforceYtd && query.meta.variant == "monthly" ? "Jan -" : null} {metric.subcolumns[i]}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          {/* EMPTY SPACE */}
          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableHeading, classes.tableCellSubHeader)} style={{ whiteSpace: "nowrap" }} />
            {metric.columns.map((name, i) => {
              if (query.meta.variant == "default" && name.includes("Target")) {
                if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                  return;
                }
                return <TableCell variant={"body"} key={name} className={classnames(classes.tableHeader)} />;
              }
              if (query.meta.variant != "default" && name.includes("Target")) {
                if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                  return;
                }
                return <TableCell variant={"body"} key={name} className={classnames(classes.tableHeader)} />;
              }
              return (
                <TableCell
                  variant={"body"}
                  key={name}
                  className={classnames(
                    classes.tableHeader,
                    i == 0 && benchmarks[i] !== "" && classes.tableCellSubHeaderBM,
                    i == 1 && benchmarks[i] !== "" && classes.tableCellSubHeaderMD,
                  )}
                />
              );
            })}
          </TableRow>

          <TableBody>
            {query.meta && (
              <TableRow className={classes.hidden}>
                <TableCell className={classes.tableGuidance} />
                <TableCell className={classnames(classes.tableHeading, classes.tableCellSubHeader)} style={{ whiteSpace: "nowrap" }}>{`View: ${
                  viewOptions[query.meta.selectedAgg]
                }`}</TableCell>
                {metric.columns.map((name, i) => {
                  if (query.meta.variant == "default" && name.includes("Target")) {
                    if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                      return;
                    }
                    return <TableCell variant={"body"} key={name} className={classnames(classes.tableHeader)} />;
                  }
                  if (query.meta.variant != "default" && name.includes("Target")) {
                    if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                      return;
                    }
                    return <TableCell variant={"body"} key={name} className={classnames(classes.tableHeader)} />;
                  }
                  return (
                    <TableCell
                      variant={"body"}
                      key={name}
                      className={classnames(
                        classes.tableHeader,
                        i == 0 && benchmarks[i] !== "" && classes.tableCellSubHeaderBM,
                        i == 1 && benchmarks[i] !== "" && classes.tableCellSubHeaderMD,
                      )}
                    />
                  );
                })}
              </TableRow>
            )}
            {metric.data.map((row, ri) => {
              const rowLen = row.length - 1;
              const rowIdx = metric.rows[ri];
              if (metric.measures[ri] == TableRows.Space) {
                return (
                  <TableRow>
                    <TableCell className={classes.tableGuidance} />
                    <TableCell className={classnames(classes.tableHeading, classes.tableCellSubHeader)} style={{ whiteSpace: "nowrap" }} />
                    {metric.columns.map((name, i) => {
                      if (query.meta.variant == "default" && name.includes("Target")) {
                        if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                          return;
                        }
                        return <TableCell variant={"body"} key={name} className={classnames(classes.tableHeader)} />;
                      }
                      if (query.meta.variant != "default" && name.includes("Target")) {
                        if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                          return;
                        }
                        return <TableCell variant={"body"} key={name} className={classnames(classes.tableHeader)} />;
                      }
                      return (
                        <TableCell
                          variant={"body"}
                          key={name}
                          className={classnames(
                            classes.tableHeader,
                            i == 0 && benchmarks[i] !== "" && classes.tableCellSubHeaderBM,
                            i == 1 && benchmarks[i] !== "" && classes.tableCellSubHeaderMD,
                          )}
                        />
                      );
                    })}
                  </TableRow>
                );
              }
              if (metric.measures[ri] == TableRows.Header) {
                return (
                  <TableRow>
                    <TableCell className={classes.tableGuidance} />
                    <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)} style={{ whiteSpace: "nowrap" }}>
                      <span
                        style={
                          metric.style[ri]
                            ? {
                                paddingLeft: 10 * metric.style[ri].depth,
                                fontWeight: metric.style[ri].bold ? "bold" : "normal",
                              }
                            : {}
                        }
                      >
                        {translation[rowIdx.trim()] ? <FormattedMessage id={translation[rowIdx.trim()]} /> : rowIdx.trim()}
                        {metric.divideMeasure[ri] && " '000"}
                      </span>
                    </TableCell>
                    {metric.columns.map((name, i) => {
                      if (query.meta.variant == "default" && name.includes("Target")) {
                        if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                          return;
                        }
                        return <TableCell variant={"body"} key={name} className={classnames(classes.tableHeader)} />;
                      }
                      if (query.meta.variant != "default" && name.includes("Target")) {
                        if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                          return;
                        }
                        return <TableCell variant={"body"} key={name} className={classnames(classes.tableHeader)} />;
                      }
                      return (
                        <TableCell
                          variant={"body"}
                          key={name}
                          className={classnames(
                            classes.tableHeader,
                            i == 0 && benchmarks[i] !== "" && classes.tableCellSubHeaderBM,
                            i == 1 && benchmarks[i] !== "" && classes.tableCellSubHeaderMD,
                          )}
                        />
                      );
                    })}
                  </TableRow>
                );
              }
              return (
                <TableRow key={ri}>
                  <TableCell className={classes.tableGuidance}>
                    {getGuidanceStr(metric.measures[ri]) && <MetricGuidance guidanceHTML={getGuidanceStr(metric.measures[ri])} name={metric.rows[ri]} />}
                  </TableCell>
                  <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)} style={{ whiteSpace: "nowrap" }}>
                    <span
                      style={
                        metric.style[ri]
                          ? {
                              paddingLeft: 10 * metric.style[ri].depth,
                              fontWeight: metric.style[ri].bold ? "bold" : "normal",
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }
                          : {
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }
                      }
                    >
                      {translation[rowIdx.trim()] ? <FormattedMessage id={translation[rowIdx.trim()]} /> : rowIdx.trim()}
                      {metric.divideMeasure[ri] && " '000"}
                    </span>
                  </TableCell>
                  {row.map((data, i) => {
                    // this section only for BM and MD, they are always the first coloumns
                    if (i < 2) {
                      if (benchmarks[0] == "") {
                        return;
                      }
                      if (!!data[1]) {
                        return (
                          <TableCell
                            key={i}
                            className={classnames(
                              classes.tableCell,
                              i == 0 && benchmarks[0] != "" && classes.tableCellSubHeaderBM,
                              i == 1 && benchmarks[0] != "" && classes.tableCellSubHeaderMD,
                            )}
                          >
                            {data[0] || "-"}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={i}
                          className={classnames(
                            classes.tableCell,
                            i == 0 && benchmarks[0] != "" && classes.tableCellSubHeaderBM,
                            i == 1 && benchmarks[0] != "" && classes.tableCellSubHeaderMD,
                          )}
                        >
                          -
                        </TableCell>
                      );
                    }

                    if (query.meta.variant == "default" && i < 4) {
                      if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                        return;
                      }
                      if (benchmarks[0] == "") {
                        return;
                      }
                      return (
                        <Tooltip title={<Typography style={{ color: "white" }}>{metric.aggregationOverride[ri] || "sum"}</Typography>} placement={"right"}>
                          <TableCell key={i} className={classnames(classes.tableCell)}>
                            {formatData(data[0])}
                          </TableCell>
                        </Tooltip>
                      );
                    }
                    if (query.meta.variant != "default" && i < 3) {
                      if (!hasTargets && !(query.forecast.dealers.length < 0)) {
                        return;
                      }
                      if (benchmarks[0] == "") {
                        return;
                      }
                      return (
                        <Tooltip title={<Typography style={{ color: "white" }}>{metric.aggregationOverride[ri] || "sum"}</Typography>} placement={"right"}>
                          <TableCell key={i} className={classnames(classes.tableCell)}>
                            {formatData(data[0])}
                          </TableCell>
                        </Tooltip>
                      );
                    }

                    // Any data before the last coloumn (the variance column) will be procssed here.
                    if (i < rowLen) {
                      if (metric.columns[i] == "YTD" || metric.columns[i] == "YTD-1") {
                        return (
                          <Tooltip title={<Typography style={{ color: "white" }}>{metric.aggregationOverride[ri] || "sum"}</Typography>} placement={"right"}>
                            <TableCell
                              key={i}
                              className={classnames(
                                classes.tableCell,
                                classes.pointer,
                                // NOTE: There used to be colouring (red and orange) for these metrics, please check git-history if you want to re-instate.
                              )}
                            >
                              {formatData(data[0])}
                            </TableCell>
                          </Tooltip>
                        );
                      }
                      return (
                        <TableCell
                          key={i}
                          className={classnames(
                            classes.tableCell,
                            // NOTE: There used to be colouring (red and orange) for these metrics, please check git-history if you want to re-instate.
                          )}
                        >
                          {formatData(data[0])}
                        </TableCell>
                      );
                    }

                    // We're on the last coloumn now, which is always varianace unless you're on "Loss Dealer" so we're checking that this metric is Var and we're on the last one.
                    return (
                      <TableCell
                        key={i}
                        className={classnames(
                          classes.tableCell,
                          {
                            [classes.red]: metric.columns[i] == "Var" ? (metric.classification[ri] == "income" ? data[1] < 0 : data[1] > 0) : false,
                          },
                          "last-cell",
                        )}
                      >
                        {/* If the YTD-1 is not set we display dash for variance. */}
                        {row[i - 1][0] ? formatData(data[0]) : "-"}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export const CustomMetricTable = withTheme()(withStyles(styles)(MetricTableUnstyled));
